import * as React from 'react';
import { Query } from "react-apollo";
import { gql } from "apollo-boost";
import styles from './Contacts.module.scss';
import Table from "../../viewComponents/Table/Table";
import CenteredLoadingIcon from "../../viewComponents/CenteredLoadingIcon/CenteredLoadingIcon";
import { Company, UserInfo } from "../../types/UserInfo";
import { ContractContact, getRoleDescription } from "../../types/ContractContact";
import { AddContactForm, EditContactForm } from './Form';
import { useEffect, useState } from "react";

interface ContractContactsProps {
    contractId: string,
    preferredContractName: string,
    preferredBuildingName: string,
    contractContacts: ContractContact[],
    title?: string,
    loading: boolean,
    preferredCompany: Company,
}


const ContractContactsViewBody = ({ preferredCompany, contractId, preferredContractName, preferredBuildingName, title, contractContacts, loading }: {
    preferredCompany: Company,
    contractId: string,
    preferredContractName: string,
    preferredBuildingName: string,
    title?: string,
    contractContacts: ContractContact[],
    loading: boolean
}) => {

    const [showAddForm, setShowAddForm] = React.useState(false);
    const [showEditForm, setShowEditForm] = React.useState(false);
    const [selectedContact, setSelectedContact] = React.useState<ContractContact>()
    const [contacts, setContacts] = React.useState<ContractContact[]>(contractContacts ? contractContacts : []);
    const [queueContacts, setQueueContacts] = React.useState<ContractContact[]>([]);
    const [aggregatedContacts, setAggregatedContacts] = React.useState<ContractContact[]>([]);
    const [missingRoles, setMissingRoles] = React.useState<Array<string>>();

    React.useEffect(() => {
        const reqRoles = ["brann", "kontrakt", "forvaltnin", "moms"];
        let temp: string[] = [];
        if (contacts && queueContacts && (contacts.length > 0 || queueContacts.length > 0)) {
            reqRoles.map(r => {
                if (!contacts.find(c => c.roleId.toLowerCase() == r)
                    && !queueContacts.find(c => c.roleId.toLowerCase() == r)) {
                    temp.push(r)
                }
            })
            setMissingRoles(temp)
        }
    }, [contacts, queueContacts])

    React.useEffect(() => {
        setContacts(contractContacts ? contractContacts : []);
    }, [contractContacts]);

    if (loading) {
        return <>
            <Table className={styles['contract-contacts-table']}>
                <caption>{title ? title : "Forvaltningsteam"}</caption>
            </Table>
            <CenteredLoadingIcon />
        </>
    }
    const canDelete = (contractContact: ContractContact | undefined) => {
        if (contractContact) {
            if ((contractContact.roleId.toLowerCase() == "brann" && aggregatedContacts.filter(c => c.roleId.toLowerCase() == "brann").length < 2) ||
                (contractContact.roleId.toLowerCase() == "kontrakt" && aggregatedContacts.filter(c => c.roleId.toLowerCase() == "kontrakt").length < 2) ||
                (contractContact.roleId.toLowerCase() == "forvaltnin" && aggregatedContacts.filter(c => c.roleId.toLowerCase() == "forvaltnin").length < 2) ||
                (contractContact.roleId.toLowerCase() == "moms" && aggregatedContacts.filter(c => c.roleId.toLowerCase() == "moms").length < 2)) {
                return false
            } else return true
        }
        else {
            return false
        }
    }

    return (
        <>
            <h2 className="headerBold40">KONTAKTLISTE</h2>
            {missingRoles && missingRoles.length > 0 && <>
                <p className={styles.warningMessage}>Disse nødvendige rollene mangler i kontaktlisten:
                    <span>
                        {missingRoles.map(r => {
                            let description = getRoleDescription(r);
                            return <span className={styles.roleName}>
                                {r.toLowerCase() == "forvaltnin" ? "Forvaltning" : r}

                                {description && <span className={styles.roleNameTooltip}>
                                    {description}
                                </span>}
                            </span>
                        })}
                    </span>
                </p>
            </>}
            <Table className={styles['contract-contacts-table']}>
                <tbody>
                    {contacts.map((contractContact: ContractContact, index) => {
                        return (
                            <ContractContactRow
                                key={index}
                                toBeDeleted={false}
                                canDelete={canDelete(contractContact)}
                                contractContact={contractContact}
                                preferredCompany={preferredCompany}
                                roleDescription={getRoleDescription(contractContact.roleId)}
                                contractId={contractId}
                                onEditCallBack={() => {
                                    setSelectedContact(contractContact)
                                    setShowEditForm(true)
                                }}
                            />
                        )
                    })}
                </tbody>
            </Table >
            {showAddForm && (
                <AddContactForm
                    contractId={contractId}
                    preferredCompany={preferredCompany}
                    contacts={contacts}
                    setShowAddForm={setShowAddForm}
                    onAdded={(contact) => {
                        setContacts([...contacts, contact]);
                    }}
                />
            )}
            {showEditForm && selectedContact && (
                <EditContactForm
                    contact={selectedContact}
                    contractId={contractId}
                    preferredCompany={preferredCompany}
                    contacts={contacts}
                    setShowAddForm={setShowEditForm}
                    canDelete={canDelete(selectedContact)}
                    onChanged={(contact) => {
                        setContacts(contacts.map((old) => {
                            if (old.roleId === contact.roleId) {
                                return contact;
                            }
                            return old;
                        }));
                    }}
                />
            )}
            <div className={[styles["addContactWrapper"], ""].join(" ")}>
                <span
                    className={[styles["contactActionBtn"], "blackActionBtn"].join(" ")}
                    onClick={() => { setShowAddForm(true) }}
                >
                    LEGG TIL NY KONTAKT
                </span>
            </div>
        </>
    )
}

const ContractContactRow = (props: {
    toBeDeleted: boolean,
    contractContact: ContractContact,
    roleDescription: string | null,
    preferredCompany: Company,
    contractId: string,
    canDelete: boolean,
    onEditCallBack: () => void,
}) => {
    return <tr className={[styles["tableRowWrapper"], "dataRow"].join(" ")}>
        <td>{props.contractContact.name}</td>
        <td>
            <span className={styles.roleName}>
                {props.contractContact.roleId.toLowerCase() == "forvaltnin" ? "Forvaltning" : props.contractContact.roleId}
                {props.roleDescription && <span className={styles.roleNameTooltip}>
                    {props.roleDescription}
                </span>}
            </span>
        </td>
        <td className={props.toBeDeleted ? styles["toBeDeleted"] : ""}>{props.contractContact.email}</td>
        <td className={props.toBeDeleted ? styles["toBeDeleted"] : ""}>{props.contractContact.phone}</td>
        <td className={styles["contactRowActionBtnTD"]}>
            <span onClick={props.onEditCallBack}>
                Endre
            </span>
        </td>
    </tr>
}

export class ContractContacts extends React.Component<ContractContactsProps> {
    render(): React.ReactNode {
        const { contractId, preferredContractName, preferredBuildingName, title, contractContacts, loading, preferredCompany } = this.props

        return (
            <>
                <ContractContactsViewBody preferredCompany={preferredCompany} preferredContractName={preferredContractName} preferredBuildingName={preferredBuildingName} contractId={contractId} title={title} contractContacts={contractContacts} loading={loading} />
            </>
        )
    }
}

interface ContractContactContainerProps {
    userInfo: UserInfo,
    contractId: string,
    preferredContractName: string,
    preferredBuildingName: string,
    preferredCompany: Company
}

export const getContacts = async (dataAreaId: string, contractId: string) => {
    let response = await fetch("/api/dynamics/contacts", {
        credentials: "include",
        method: "POST",
        body: JSON.stringify({
            "dataAreaId": dataAreaId,
            "contractId": contractId,
            "roleId": "",
            "email": "",
            "name": "",
            "phone": ""
        }),
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return await response.json();
}

// The container component should do all GraphQL queries
const ContractContactsContainer = (props: ContractContactContainerProps) => {
    let { userInfo, contractId, preferredContractName, preferredBuildingName, preferredCompany } = props;

    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [contacts, setContacts] = useState<ContractContact[]>([]);
    useEffect(() => {
        setIsError(false);
        setIsLoading(true);
        setContacts([]);
        getContacts(preferredCompany.id, props.contractId)
            .then((ok: ContractContact[]) => {
                setContacts(ok);
                setIsError(false);
                setIsLoading(false);
            })
            .catch((err) => {
                console.error(err);
                setIsError(true);
                setContacts([]);
            })
    }, [props.contractId, preferredCompany.id]);


    if (isError) {
        return <span>Kunne ikke hente kontakter</span>;
    }
    return (
        <span>
            <ContractContacts
                preferredCompany={preferredCompany}
                preferredBuildingName={preferredBuildingName}
                preferredContractName={preferredContractName}
                contractId={contractId}
                contractContacts={contacts}
                loading={isLoading}
            />
        </span>
    );
}

export default ContractContactsContainer
